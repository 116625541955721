
import Vue from 'vue'
import Component from 'vue-class-component'
import StatusCenterStep from '../model/status-center-step'
import { Prop, Watch } from 'vue-property-decorator'
import ResponsiveUtils from '@/ui/util/responsive-utils'

let GLOBAL_IS_INITIALIZED = false

/**
 * Renders a next step view for status center.
 *
 * Events:
 * scroll-to-step(StatusCenterStep): emitted when the scroll to step button is clicked
 *    with the next step object as parameter.
 */
@Component
export default class StatusCenterNextStep extends Vue {
  /**
   * All step groups.
   */
  @Prop({ required: true })
  stepGroups!: StatusCenterStep[][]

  isInitialized = GLOBAL_IS_INITIALIZED

  isNextStepShown = false
  nextStep: StatusCenterStep | null = null

  created() {
    this.onNextStepChanged()
  }

  mounted() {
    setTimeout(() => {
      this.isInitialized = true
      GLOBAL_IS_INITIALIZED = true
    }, 400)
  }

  scrollToStep() {
    this.$emit('scroll-to-step', this.nextStep)
  }

  scrollToStepMobile() {
    if (ResponsiveUtils.isPhone()) {
      this.scrollToStep()
    }
  }

  private get computedNextStep(): StatusCenterStep | null {
    for (let i = 0; i < this.stepGroups.length; i++) {
      const group = this.stepGroups[i]
      for (let j = 0; j < group.length; j++) {
        const step = group[j]
        if (!step.isHeading && (!step.condition || step.condition()) && !step.isChecked) {
          return step
        }
      }
    }
    return null
  }

  @Watch('computedNextStep')
  private onNextStepChanged() {
    const nextStep = this.computedNextStep
    if (nextStep) {
      this.nextStep = nextStep
      this.isNextStepShown = true
    } else {
      this.isNextStepShown = false
    }
  }
}
