import store from '@/store/store'
import RouteNames from '@/ui/router/route-names'
import StatusCenterStep from './model/status-center-step'
import StatusCenterConditions from './status-center-conditions'
import StatusCenterResultCalculators from './status-center-result-calculators'

/**
 * List of grouped steps for the status center screen.
 */
const stepGroups: StatusCenterStep[][] = [
  [
    {
      id: 1,
      isHeading: true,
      title: 'ELIGIBILITY',
      description:
        'Complete your profile and find out your CRS points. ' +
        'In addition, answer eligibility questions to find out immigration programs for which ' +
        'you can be eligible.',
    },
    {
      id: 2,
      title: 'Calculate CRS points',
      routeName: RouteNames.GUIDE_ELIGIBILITY,
      resultCalculator: StatusCenterResultCalculators.calculatePoints,
    },
    {
      id: 3,
      title: 'Find out which programs you are eligible for',
      routeName: RouteNames.GUIDE_ELIGIBILITY,
      routeElementId: 'eligibility-summary',
      resultCalculator: StatusCenterResultCalculators.eligiblePrograms,
    },
    {
      id: 4,
      title: 'Pass a language test for one of the official languages (English or French)',
      routeName: RouteNames.GUIDE_ELIGIBILITY,
      routeElementId: 'A_LANGUAGES',
      resultCalculator: StatusCenterResultCalculators.languageResults,
    },
    {
      id: 5,
      title: 'Pass a language test for the second official language (optional)',
      routeName: RouteNames.GUIDE_ELIGIBILITY,
      routeElementId: 'A_LANGUAGES',
      resultCalculator: StatusCenterResultCalculators.secondLanguageResults,
    },
    {
      id: 6,
      title: 'Educational credential assessment',
      routeName: RouteNames.GUIDE_ITA,
      routeElementId: 'guide-step-4',
      condition: StatusCenterConditions.educationalCredentialAssessment,
      resultCalculator: StatusCenterResultCalculators.currentEducationLevel,
    },
    {
      id: 7,
      title: 'Language test results for your spouse',
      routeName: RouteNames.GUIDE_ELIGIBILITY,
      routeElementId: 'B_LANGUAGES',
      resultCalculator: StatusCenterResultCalculators.spouseLanguageResults,
      condition: () => store.state.profile.profile.hasSpouse,
    },
    {
      id: 8,
      title: 'Educational credential assessment for your spouse',
      routeName: RouteNames.GUIDE_ELIGIBILITY,
      routeElementId: 'B_EDUCATION',
      resultCalculator: StatusCenterResultCalculators.spouseEducationLevel,
      condition: StatusCenterConditions.spouseEducationalCredentialAssessment,
    },
  ],
  [
    {
      id: 100,
      title: 'POOL',
      isHeading: true,
      description:
        'Get improvement suggestions to achieve desired points to get an invitation to apply. ' +
        'Follow the IRCC guide to create your account and submit your Express Entry profile.',
      permission: 'poolGuide',
    },
    {
      id: 101,
      title: 'Improve CRS points',
      permission: 'poolGuide',
      routeName: RouteNames.GUIDE_POOL,
      resultCalculator: StatusCenterResultCalculators.targetPoints,
    },
    {
      id: 102,
      title: 'Create IRCC account',
      permission: 'poolGuide',
      routeName: RouteNames.GUIDE_POOL,
      routeElementId: 'ircc-guide',
      resultCalculator: StatusCenterResultCalculators.irccGuideProgress,
    },
    {
      id: 103,
      title: 'Submit Express Entry profile',
      permission: 'poolGuide',
      routeName: RouteNames.GUIDE_POOL,
      routeElementId: 'guide-step-2',
      resultCalculator: StatusCenterResultCalculators.combineCalculators(
        StatusCenterResultCalculators.expressEntryProfileValidity,
        StatusCenterResultCalculators.expressEntryProfileGuideProgress
      ),
    },
  ],
  [
    {
      id: 200,
      title: 'ITA',
      description:
        'Plan required documents ahead of time after you get your Invitation to Apply. ' +
        'ITA guide will help you efficiently gather the required documents.',
      isHeading: true,
      permission: 'itaGuide',
    },
    {
      id: 201,
      title: 'Get Invitation To Apply (ITA)',
      permission: 'itaGuide',
      routeName: RouteNames.ROUNDS,
      resultCalculator: StatusCenterResultCalculators.itaRoundPoints,
    },
    {
      id: 202,
      title: 'Prepare documents',
      permission: 'itaGuide',
      routeName: RouteNames.GUIDE_ITA,
      routeElementId: 'guide-step-2',
      resultCalculator: StatusCenterResultCalculators.supportingDocumentsGuideProgress,
    },
    {
      id: 203,
      title: 'Submit PR application',
      permission: 'itaGuide',
      routeName: RouteNames.GUIDE_ITA,
      resultCalculator: StatusCenterResultCalculators.combineCalculators(
        StatusCenterResultCalculators.itaDeadline,
        StatusCenterResultCalculators.prApplicationGuideProgress
      ),
    },
  ],
  [
    {
      id: 300,
      title: 'PR',
      isHeading: true,
      description:
        'Plan your final step. Do you need a visa? How long is your Confirmation of Permanent ' +
        'Residence valid for? How to complete the landing process to finally receive your ' +
        'PR status?',
      permission: 'prGuide',
    },
    {
      id: 301,
      title: 'Confirmation of Permanent Residence (CoPR)',
      permission: 'prGuide',
      routeName: RouteNames.GUIDE_PR,
      resultCalculator: StatusCenterResultCalculators.visaDeadline,
    },
    {
      id: 302,
      title: 'Permanent Resident Visa',
      permission: 'prGuide',
      routeName: RouteNames.GUIDE_PR,
      resultCalculator: StatusCenterResultCalculators.visaGuideProgress,
    },
    {
      id: 303,
      title: 'PR Landing',
      permission: 'prGuide',
      routeName: RouteNames.GUIDE_PR,
      routeElementId: 'landing-guide',
      resultCalculator: StatusCenterResultCalculators.combineCalculators(
        StatusCenterResultCalculators.landingDeadline,
        StatusCenterResultCalculators.landingGuideProgress
      ),
    },
    {
      id: 304,
      title: 'PR card',
      permission: 'prGuide',
      routeName: RouteNames.GUIDE_PR,
      routeElementId: 'landing-guide',
    },
  ],
]

stepGroups.forEach(group => group.forEach(step => (step.isChecked = false)))

export default stepGroups
