
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import ProfileModuleUtils from '@/store/modules/profile-module-utils'
import ResponsiveUtils from '@/ui/util/responsive-utils'
import StatusCenterStep from '../model/status-center-step'

import { StatusCenterActions } from '../status-center-actions'
import Utils from '@/common/util/utils'

@Component
export default class StatusCenterStepView extends Vue {
  /** The step to render */
  @Prop({ required: true })
  step!: StatusCenterStep

  /** True if this step is the last one in the containing group */
  @Prop({ type: Boolean, required: true })
  isLast!: boolean

  /** True if the step is enabled. */
  @Prop({ type: Boolean, required: true })
  enabled!: boolean

  private _computedResult: string | null = null
  isComputedResultLoading = false

  async beforeMount() {
    if (this.step.resultCalculator) {
      const result = this.step.resultCalculator(this)
      if (result instanceof Promise) {
        this.isComputedResultLoading = true
        this._computedResult = await result
        this.isComputedResultLoading = false
      }
    }
  }

  get computedResult(): string | null {
    if (this._computedResult || this.isComputedResultLoading) {
      return this._computedResult
    } else if (this.step.resultCalculator) {
      return this.step.resultCalculator(this) as string
    }
    return null
  }

  get shouldShowStep(): boolean {
    const step = this.step
    return !step.condition || step.condition()
  }

  onStepClicked() {
    if (this.step.routeName) {
      this.navigateToStep()
    } else if (this.step.action) {
      this.triggerStepAction()
    }
  }

  toggleStepChecked() {
    if (this.enabled) {
      this.onStepValueChanged(!this.step.isChecked)
    }
  }

  onStepValueChanged(value: boolean) {
    this.step.isChecked = value
    ProfileModuleUtils.setStatusCenterStepValue(this.step.id, value)
  }

  triggerStepAction() {
    if (!this.enabled) {
      return
    }

    const step = this.step
    if (step.action) {
      step.action(this)
    }
  }

  navigateToStep() {
    if (!this.enabled) {
      return
    }

    const step = this.step
    if (step.routeName) {
      this.$router.push({
        name: step.routeName,
        params: step.routeElementId ? { elementId: step.routeElementId } : void 0,
      })
    }
  }

  get isPhone(): boolean {
    return ResponsiveUtils.isPhone()
  }

  get hasAction(): boolean {
    return !!(this.step.link || this.step.routeName || this.step.action)
  }

  get actionIcon(): string {
    const step = this.step
    if (ResponsiveUtils.isPhone() && (step.routeName || step.link)) {
      return 'chevron_right'
    } else if (step.actionIcon) {
      return step.actionIcon
    } else if (step.action) {
      if (ResponsiveUtils.isPhone()) {
        return 'help'
      } else {
        return 'question'
      }
    } else {
      return 'chevron_right'
    }
  }
}
