
import PermissionChecker from '@/data/claims/permission-checker'
import { AuthTypes } from '@/store/modules/auth.module'
import store from '@/store/store'
import UiUtils from '@/ui/util/ui-utils'
import WindowManager from '@/ui/util/window-manager'
import { Permission, StatusCenterProgress, SubscriptionInfo } from 'shared-entities'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import StatusCenterNextStep from './components/StatusCenterNextStep.vue'
import StatusCenterStepView from './components/StatusCenterStepView.vue'
import StatusCenterStep from './model/status-center-step'
import steps from './status-center-step-groups'

let GLOBAL_IS_PERMISSION_MAP_INITIALIZED = false

@Component({
  components: {
    StatusCenterStepView,
    StatusCenterNextStep,
  },
})
export default class StatusCenterScreen extends Vue {
  stepGroups: StatusCenterStep[][] = steps

  checkableSteps: StatusCenterStep[] = Array.prototype.concat
    .apply([], steps)
    .filter(step => !step.label && (!step.condition || step.condition()))

  totalStepCount: number = 0

  private permissionMap: { [key in Permission]?: boolean } = {}
  isPermissionMapInitialized = GLOBAL_IS_PERMISSION_MAP_INITIALIZED

  isProgressHeaderSticky = false

  highlightedSteps: { [key: number]: boolean } = {}

  private headerElementY = 0

  mounted() {
    this.totalStepCount = this.checkableSteps.length

    this.updatePermissionMap()
    this.onStatusCenterProgressChanged(store.state.profile.statusCenterProgress)

    this.updateHeaderElementY()
    // Do it again after a delay to account for animations
    setTimeout(() => this.updateHeaderElementY(), 300)

    WindowManager.addScrollListener(this.onScroll)
  }

  private updateHeaderElementY() {
    this.headerElementY = UiUtils.getElementY(this.$el as HTMLElement, true)
    this.onScroll(WindowManager.scrollY)
  }

  beforeDestroy() {
    WindowManager.removeScrollListener(this.onScroll)
  }

  private onScroll(scrollY: number) {
    this.isProgressHeaderSticky = this.headerElementY !== 0 && scrollY >= this.headerElementY
  }

  private get subscription(): SubscriptionInfo | null {
    return store.getters[AuthTypes.getters.subscription]
  }

  private get statusCenterProgress(): StatusCenterProgress {
    return store.state.profile.statusCenterProgress
  }

  get stepsCompleteCount(): number {
    return this.checkableSteps.sum(step => (step.isChecked ? 1 : 0))
  }

  get progressValue(): number {
    return (this.stepsCompleteCount * 100) / this.totalStepCount
  }

  isStepEnabled(step: StatusCenterStep): boolean {
    if (!this.isPermissionMapInitialized) {
      return false
    }
    if (step.permission) {
      return this.permissionMap[step.permission] || false
    } else {
      return true
    }
  }

  scrollToStep(step: StatusCenterStep) {
    UiUtils.scrollToRef(this.$refs, step.id.toString(), {
      smooth: true,
      accountForHeader: true,
      // Add 24 pixels of extra padding.
      offset: -(this.$refs.header as HTMLElement).clientHeight - 24,
      callback: () => {
        this.highlightStep(step)
      },
    })
  }

  private highlightStep(step: StatusCenterStep) {
    Vue.set(this.highlightedSteps, step.id, true)
    setTimeout(() => {
      this.highlightedSteps[step.id] = false
    }, 500)
  }

  @Watch('subscription')
  private onSubscriptionChanged() {
    this.updatePermissionMap()
  }

  @Watch('statusCenterProgress')
  private onStatusCenterProgressChanged(progress: StatusCenterProgress) {
    if (progress) {
      this.stepGroups.forEach(group => {
        group.forEach(step => {
          step.isChecked = !!progress[step.id]
        })
      })
    }
  }

  private async updatePermissionMap() {
    for (let i = 0; i < this.stepGroups.length; i++) {
      const group = this.stepGroups[i]
      for (let j = 0; j < group.length; j++) {
        const step = group[j]

        if (step.permission) {
          this.permissionMap[step.permission] = await PermissionChecker.check(step.permission)
        }
      }
    }

    GLOBAL_IS_PERMISSION_MAP_INITIALIZED = true
    this.isPermissionMapInitialized = true
    this.$forceUpdate()
  }
}
