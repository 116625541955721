import store from '@/store/store'
import { EducationLevel, Profile } from 'shared-entities'

/**
 * Defines conditions that determine if status center steps should be shown.
 */
export default class StatusCenterConditions {
  static educationalCredentialAssessment(): boolean {
    const profile = store.state.profile.profile
    const educationLevel = profile.education
    if (!educationLevel || educationLevel < EducationLevel.SECONDARY) {
      return false
    }

    if (educationLevel == EducationLevel.SECONDARY) {
      return true
    }

    let educationYears = 0
    switch (educationLevel) {
      case EducationLevel.ONE_YEAR_DEGREE:
        educationYears = 1
        break
      case EducationLevel.TWO_YEAR_DEGREE:
        educationYears = 2
        break
      case EducationLevel.BACHELORS_DEGREE:
      case EducationLevel.TWO_DEGREES:
      case EducationLevel.MASTERS_DEGREE:
      case EducationLevel.PHD:
        educationYears = 3
        break
    }

    const canadianEducationYears = profile.canadianEducation
    return !canadianEducationYears || canadianEducationYears < educationYears
  }

  static spouseEducationalCredentialAssessment(): boolean {
    const profile = store.state.profile.profile
    if (!profile.hasSpouse) {
      return false
    }

    const spouse = profile.spouse
    if (!spouse.education || spouse.education < EducationLevel.SECONDARY) {
      return false
    }

    return true
  }
}
