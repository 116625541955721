import EligibilityStatus from '@/data/entity/programs/eligibility-status.entity'
import ProgramId from '@/data/entity/programs/program-id.entity'
import GuideDeadlineCalculator from '@/data/guide/guide-deadline-calculator'
import PointCalculator from '@/data/point-calculator/point-calculator'
import store from '@/store/store'
import { GuideName, LanguageTestScores, Profile, GuideStepGroupId } from 'shared-entities'
import { LanguageTest } from '@/data/entity/profile/language-test'
import { EDUCATION_LEVEL_OPTIONS } from '@/data/point-calculator/categories/core/education-point-calculator'
import EditableProfile from '@/data/entity/profile/editable-profile.entity'
import PoolDataRepository from '@/data/repository/pool-data.repository'
import { StepViewItem } from '@/ui/components/step-view/step-view-item'
import { StatusCenterResultCalculator } from './model/status-center-step'

import irccGuideSteps from '@/ui/screens/guide/pool/ircc-guide/ircc-guide-steps'
import itaGuideSteps from '@/ui/screens/guide/ita/ita-guide-steps'
import visaGuideSteps from '@/ui/screens/guide/pr/visa-guide-steps'
import landingGuideSteps from '@/ui/screens/guide/pr/landing-guide-steps'

export default class StatusCenterResultCalculators {
  static combineCalculators(
    ...calculators: StatusCenterResultCalculator[]
  ): StatusCenterResultCalculator {
    return (instance: Vue) =>
      calculators
        .map(it => it(instance))
        .filter(it => !!it)
        .map(it => `<span>${it}</span>`)
        .join('<br>')
  }

  static calculatePoints(): string {
    const points = store.state.profile.points
    return `${points.totalPoints} points of ${PointCalculator.MAX_CRS_POINTS}`
  }

  static eligiblePrograms(): string {
    const eligibility = store.state.profile.eligibility
    const eligibleProgramIds = Object.keys(eligibility)
      .filter(key => {
        const programId = key as ProgramId
        return eligibility[programId].status === EligibilityStatus.ELIGIBLE
      })
      .map(programId => programId.toUpperCase())

    if (eligibleProgramIds.length) {
      return `Your eligibility: ${eligibleProgramIds.join(', ')}`
    } else {
      return ''
    }
  }

  static languageResults(): string {
    const profile = store.state.profile.profile
    return StatusCenterResultCalculators._languageResults(profile, profile.firstLanguageTest)
  }

  static secondLanguageResults(): string {
    const profile = store.state.profile.profile
    return StatusCenterResultCalculators._languageResults(profile, profile.secondLanguageTest)
  }

  static spouseLanguageResults(): string {
    const spouse = store.state.profile.profile.spouse
    return StatusCenterResultCalculators._languageResults(spouse, spouse.firstLanguageTest)
  }

  private static _languageResults(
    profile: EditableProfile,
    languageTest: LanguageTest | null
  ): string {
    if (languageTest) {
      const languageTestType = languageTest.type
      const scores = profile.langLevel[languageTestType]
      if (scores) {
        return `
        <div style="font-size: 16px">
        <style>
        </style>
          <strong style="color:black">${languageTestType} / </strong>
          <small>${StatusCenterResultCalculators.formatLanguageScores(languageTest, scores)}</small>
        </div>
        `
      }
    }
    return ''
  }

  private static formatLanguageScores(test: LanguageTest, scores: LanguageTestScores): string {
    function wrapScore(clbScore: number, testScore: number): string {
      if (clbScore === 10) {
        return `${testScore}+`
      }
      return testScore.toString()
    }

    const r = test.convertClbScore(scores.r, 'r')
    const w = test.convertClbScore(scores.w, 'w')
    const l = test.convertClbScore(scores.l, 'l')
    const s = test.convertClbScore(scores.s, 's')
    return (
      `<span class="x-pill">R: ${wrapScore(scores.r, r)}</span> ` +
      `<span class="x-pill">W: ${wrapScore(scores.w, w)}</span> ` +
      `<span class="x-pill">L: ${wrapScore(scores.l, l)}</span> ` +
      `<span class="x-pill">S: ${wrapScore(scores.s, s)}</span>`
    )
  }

  static currentEducationLevel(instance: Vue): string {
    const profile = store.state.profile.profile
    return StatusCenterResultCalculators._educationLevel(instance, profile)
  }

  static spouseEducationLevel(instance: Vue): string {
    const spouse = store.state.profile.profile.spouse
    return StatusCenterResultCalculators._educationLevel(instance, spouse)
  }

  private static _educationLevel(instance: Vue, profile: Profile): string {
    if (profile.education) {
      const option = EDUCATION_LEVEL_OPTIONS.find(it => it.id === profile.education)
      if (option) {
        return instance.$ts(option.shortTitle)
      }
    }
    return ''
  }

  static targetPoints(): string {
    return `Target points: ${store.state.pointImprovementGuide.targetPoints}`
  }

  static async itaRoundPoints(): Promise<string> {
    const poolData = await PoolDataRepository.getPoolData()
    return `Latest round points: ${poolData.latestRound.lowestScore}`
  }

  static expressEntryProfileValidity(instance: Vue): string {
    const daysLeft = GuideDeadlineCalculator.calculateDaysLeft('ircc')
    if (daysLeft != null) {
      const result =
        `Your profile is valid for ` + instance.$tc('plural.day', daysLeft, { choice: daysLeft })
      if (daysLeft > 30) {
        return result
      } else if (daysLeft >= 0) {
        return `<span class="text--error">${result}</span>`
      } else {
        return '<span class="text--error">Your profile has expired</span>'
      }
    }
    return ''
  }

  static itaDeadline(): string {
    return StatusCenterResultCalculators.guideDeadline('ita')
  }

  static visaDeadline(): string {
    return StatusCenterResultCalculators.guideDeadline('visa')
  }

  static landingDeadline(): string {
    return StatusCenterResultCalculators.guideDeadline('landing')
  }

  private static guideDeadline(guideName: GuideName): string {
    const daysLeft = GuideDeadlineCalculator.calculateDaysLeft(guideName)
    if (daysLeft !== null) {
      if (daysLeft >= 0) {
        const result = `Days left: ${daysLeft}`
        if (daysLeft > 30) {
          return result
        } else {
          return `<span class="text--error">${result}</span>`
        }
      } else {
        return '<span class="text--error">Expired</span>'
      }
    }
    return ''
  }

  static irccGuideProgress(): string {
    return StatusCenterResultCalculators.guideProgress(
      irccGuideSteps,
      'ircc',
      GuideStepGroupId.IrccAccount
    )
  }

  static expressEntryProfileGuideProgress(): string {
    return StatusCenterResultCalculators.guideProgress(
      irccGuideSteps,
      'ircc',
      GuideStepGroupId.ExpressEntryProfile
    )
  }

  static supportingDocumentsGuideProgress(): string {
    return StatusCenterResultCalculators.guideProgress(
      itaGuideSteps,
      'ita',
      GuideStepGroupId.PrApplicationSupportingDocuments
    )
  }

  static prApplicationGuideProgress(): string {
    return StatusCenterResultCalculators.guideProgress(
      itaGuideSteps,
      'ita',
      GuideStepGroupId.PrApplication
    )
  }

  static visaGuideProgress(): string {
    return StatusCenterResultCalculators.guideProgress(
      visaGuideSteps,
      'visa',
      GuideStepGroupId.Visa
    )
  }

  static landingGuideProgress(): string {
    return StatusCenterResultCalculators.guideProgress(
      landingGuideSteps,
      'landing',
      GuideStepGroupId.Landing
    )
  }

  private static guideProgress(
    steps: StepViewItem[],
    guideName: GuideName,
    groupId: GuideStepGroupId
  ): string {
    const allSteps = steps.find(step => step.id === groupId)!.subItems.length
    let completedSteps = 0
    const progress = store.state.profile.guideProgress[guideName]
    if (progress && progress.steps[groupId]) {
      const recordedSteps = Object.values(progress.steps[groupId])
      completedSteps = recordedSteps.filter(it => it).length
    }
    const result = `Completed steps: ${completedSteps} of ${allSteps}`
    if (completedSteps === allSteps) {
      return result
    } else if (completedSteps > 0) {
      return `<span class="text--warning">${result}</span>`
    } else {
      return `<span class="text--description">${result}</span>`
    }
  }
}
